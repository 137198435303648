@import "primeicons/primeicons.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

html,body{
    margin:0;
    padding:0;
    height:100%;
    width:auto;
    font-family: "Scania Sans Semi Condensed", "Scania Sans Condensed", Arial, Helvetica, sans-serif;
}

@font-face {
    font-family: Scania Sans Headline Bold;
    src: url('./assets/ScaniaSansCYHeadline-Bold.ttf');
}

@font-face {
    font-family: Scania Sans Headline;
    src: url('./assets/ScaniaSansCYHeadline-Regular.ttf');
}

@font-face {
    font-family: Scania Sans Semi Condensed;
    src: url('./assets/ScaniaSansCYSemiCondensed-Regular.ttf')
}

@font-face {
    font-family: Scania Sans Condensed;
    src: url('./assets/ScaniaSansCYCondensed-Regular.ttf')
}

// @import url('@scania/tegel/dist/tegel/tegel.css');

// * {
//     box-sizing: border-box;
// }

// div {
//     display: block;
// }

// .my-custom-cell-global-one {
//     background-color: blue;
//     padding: 0px !important;
// }

// .my-custom-cell-global-two {
//     background-color: aquamarine;
// }

// .my-custom-cell-global-three {
//     background-color: red;
// }
